import axiosInstance from './../axios';

export const sendSupportQuery = (payload) => {
  return axiosInstance.post('/api/support/sendSupportQuery', payload);
};
export const sendDemoQuery = (payload) => {
  return axiosInstance.post('/api/support/sendDemoQuery', payload);
};
export const sendPricingQuery = (payload) => {
  return axiosInstance.post('/api/support/sendPricingQuery', payload);
};
export const sendCustomisedQuote = (payload) => {
  return axiosInstance.post('/api/support/sendCustomisedQuote', payload);
};
export const sendReferralInvite = (payload) => {
  return axiosInstance.post('/api/support/sendReferralInvite', payload);
};
export const sendSubscribe = (payload) => {
  return axiosInstance.post('/api/support/sendSubscribe', payload);
};
