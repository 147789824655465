import Axios from 'axios';
import { cloneDeep } from 'lodash';

const axiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_BACKENDURL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(async (config) => {
  const clonedConfig = cloneDeep(config);
  const token = window.localStorage.getItem('access_token');

  clonedConfig.headers.common = {
    Authorization: `${token}`,
  };

  return clonedConfig;
});

export default axiosInstance;
