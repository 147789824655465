import { Button, Group, Textarea, TextInput } from '@mantine/core';
import React from 'react';
import toast from 'react-hot-toast';
import { supportApi } from '../../api';

const CustomisedQuote = ({ setCustomisedQuote }) => {
  const [workEmail, setWorkEmail] = React.useState('');
  const [customRequirements, setCustomRequirements] = React.useState('');
  const [availibility, setAvailibility] = React.useState('');

  const checkEmail = (email) => {
    const pattern =
      /^[^@]+@(?!(gmail|hotmail)\.com$)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(email);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (workEmail && customRequirements && availibility) {
      if (checkEmail(workEmail)) {
        supportApi
          .sendCustomisedQuote({
            workEmail,
            customRequirements,
            availibility,
          })
          .then((res) => {
            setCustomisedQuote(false);
            toast.success('Submitted Successfully');
            setWorkEmail('');
            setCustomRequirements('');
            setAvailibility('');
          });
      } else {
        toast.error('Please fill only work emails');
      }
    } else {
      toast.error('Please fill all the fields');
    }
  };

  return (
    <Group direction="column" style={{ padding: '10px 40px' }} grow>
      <TextInput
        size="md"
        radius="md"
        placeholder="Work Email"
        label="Email Id"
        value={workEmail}
        onChange={(e) => setWorkEmail(e.target.value)}
      />
      <Textarea
        size="md"
        radius="md"
        label="Custom requirments for quote"
        placeholder="Custom requirments for quote"
        alue={customRequirements}
        onChange={(e) => setCustomRequirements(e.target.value)}
      />
      <TextInput
        size="md"
        radius="md"
        placeholder="Provide a date and time"
        label="Availibility for meeting"
        value={availibility}
        onChange={(e) => setAvailibility(e.target.value)}
      />

      <Button variant="filled" color="dark" radius="lg" onClick={submitHandler}>
        Get a Quote
      </Button>
    </Group>
  );
};

export default CustomisedQuote;
