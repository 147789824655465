import React from 'react';

const Loader = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '100vh',
        alignItems: 'center',
        backgroundColor: '#fff',
      }}
    >
      <img src="/assets/Hourglass.gif" alt="" />
    </div>
  );
};

export default Loader;
