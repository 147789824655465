import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  padding: 0 100px;
  max-width: 1400px;
  width: 100%;

  @media (max-width: 400px) {
    padding: 0 10px;
  }
  @media (max-width: 991px) {
    padding: 0 30px;
  }

  @media (min-width: 1500px) {
    max-width: 1500px;
  }

  @media (min-width: 1800px) {
    max-width: 1512px;
    padding: 0 30px;
  }
`;

export const Button = styled.button`
  border-radius: ${({ bigRadius }) => (bigRadius ? '30px' : '50px')};
  background: ${({ gray }) =>
    gray
      ? 'transparent'
      : 'linear-gradient(91.76deg, #7DF1F9 4.94%, #77EE59 94.92%);'};
  padding: ${({ big }) => (big ? '18px 30px' : '8px 24px')};
  font-size: ${({ bigFont }) => (bigFont ? '32px' : '20px')};
  outline: none;
  cursor: pointer;
  border: ${({ dark }) => (dark ? '1px black solid' : '0px solid')};
  color: ${({ primary }) => (primary ? 'white' : '#202020')};
  transition: all 0.5s ease;
  font-weight: 700;

  &:hover {
    transform: translateY(-3px);
    color: ${({ gray }) => (gray ? 'black' : '#fff')};
  }
  &:active {
    transform: translateY(0.5rem);
  }

  @media only screen and (max-width: 1000px) {
    /* width: 100%; */
    padding: ${({ big }) => (big ? '18px 30px' : '10px 20px')};
  }
  @media only screen and (max-width: 375px) {
    padding: ${({ big }) => (big ? '12px 20px' : '10px 20px')};
    font-size: ${({ bigFont }) => (bigFont ? '16px' : '10px')};
  }
`;

export const OutlineButton = styled.button`
  border-radius: ${({ bigRadius }) => (bigRadius ? '30px' : '50px')};
  border: ${({ dark }) => (dark ? '2px black solid' : '2px white solid')};
  color: ${({ dark }) => (dark ? 'black' : 'white')};
  outline: none;
  padding: ${({ big }) => (big ? '18px 30px' : '10px 28px')};
  font-size: ${({ bigFont }) => (bigFont ? '32px' : '16px')};
  transition: all 0.5s ease;
  background-color: transparent;
  text-align: center;
  font-weight: 500;

  &:hover {
    transform: translateY(-3px);
    background-color: transparent;
    color: #fff;
  }

  @media only screen and (max-width: 1000px) {
    /* width: 100%; */
    padding: ${({ big }) => (big ? '18px 30px' : '10px 20px')};
  }
  @media only screen and (max-width: 375px) {
    padding: ${({ big }) => (big ? '12px 20px' : '10px 20px')};
    font-size: ${({ bigFont }) => (bigFont ? '16px' : '10px')};
  }
`;
