import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Container } from '../../styles/Globalstyles.styled';

export const Nav = styled.nav`
  width: 100%;
  height: 100px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 2px 0 rgb(0, 0, 0, 0.09);
`;

export const NavBanner = styled.div`
  background: linear-gradient(91.76deg, #18c3e6 4.94%, #a1e690 94.92%);
  font-family: 'Poppins', sans-serif;
  color: white;
  text-align: center;
  font-weight: 500;
  position: relative;
  width: 100%;
  padding: 5px 0;
`;

export const NavbarContainer = styled(Container)`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  ${Container};
`;

export const NavLogo = styled(Link)`
  color: #202020;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.5s ease;
  &:hover {
    transform: scale(1.08);
  }
`;

export const MenuIcon = styled.div`
  display: none;

  @media (max-width: 1200px) {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 3rem;
    cursor: pointer;
  }
`;

export const Menu = styled.ul`
  display: flex;
  align-items: center;

  @media screen and (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    z-index: 99;
    position: absolute;
    top: 80px;
    margin: 0px;
    padding: 0px;
    box-shadow: 1px 1px 2px 0 rgb(0, 0, 0, 0.09);
    height: 100vh;
    overflow-y: hidden;
    left: ${({ click }) => (click ? '0px' : '-100vw')};
    background: rgb(221, 250, 198);
    transition: all 0.5s ease;
  }
`;

export const MenuItem = styled.li`
  list-style: none;
  font-weight: 500;

  @media only screen and (max-width: 1200px) {
    width: 100%;
    &:hover {
      border: none;
    }
  }
`;

export const MenuLink = styled(Link)`
  text-decoration: none;
  font-weight: 400;
  font-size: 1rem;
  color: #202020;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
  transition: all 0.2s ease;

  &:hover {
  }

  &:active {
    transform: traslateY(3rem);
  }

  @media only screen and (max-width: 1200px) {
    display: block;
    padding: 16px 32px;
    text-align: center;
    transition: all 0.2s ease;
  }
`;

export const MenuItemBtn = styled.li`
  list-style: none;
  @media screen and (max-width: 1200px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const MenuLinkBtn = styled(Link)`
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
`;

export const DropDownContent = styled.div`
  display: none;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.67);
  color: #202020;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 99;
  border-radius: 10px;

  ${MenuLink} {
    padding: 8px 20px;
  }

  @media screen and (max-width: 1200px) {
    background: rgb(221, 250, 198);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);
    width: 100%;
    position: relative;
  }
`;

export const DropDownLi = styled(MenuItemBtn)`
  display: inline-block;

  &:hover ${DropDownContent} {
    display: block;
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
  }
`;

export const Dropbtn = styled.div`
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;
  color: #202020;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
  transition: all 0.2s ease;

  &:hover {
  }

  @media only screen and (max-width: 1200px) {
    display: block;
    color: #202020;
    text-align: center;
    transition: all 0.2s ease;
  }
`;
