import { Text } from '@mantine/core';
import { useInterval, useScrollLock, useToggle } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';
import { IconContext } from 'react-icons';
import { BiMenu, BiX } from 'react-icons/bi';
import { ImCross } from 'react-icons/im';
import { Breakpoint } from 'react-socks';
import logo from '../../darklogo.svg';
import {
  Dropbtn,
  DropDownContent,
  DropDownLi,
  Menu,
  MenuIcon,
  MenuLink,
  Nav,
  NavBanner,
  NavbarContainer,
  NavLogo,
} from './Navbar.styled';

const Navbar = () => {
  const [openNavigation, setOpenNavigation] = useState(false);
  const [banner, setBanner] = useState(false);
  const [bannertext, toggle] = useToggle(
    'Banner for Announcements and Marketing',
    ['Banner for Announcements and Marketing', 'Give Earth A Chance ❤️'],
  );

  const interval = useInterval(() => toggle(), 5000);
  const [scrollLocked, setScrollLocked] = useScrollLock();

  const handleClick = () => {
    setOpenNavigation(!openNavigation);
  };

  const handleMyClick = () => {
    handleClick();
    setScrollLocked(!scrollLocked);
  };

  const closeMenu = () => {
    setOpenNavigation(false);
    setScrollLocked(false);
  };

  useEffect(() => {
    interval.start();
    return interval.stop;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <IconContext.Provider value={{ color: '#ffffff' }}>
        {banner && (
          <NavBanner>
            {bannertext}
            <ImCross
              style={{ position: 'absolute', right: '40px', height: '24px' }}
              onClick={() => setBanner(false)}
            />
          </NavBanner>
        )}
        <Nav>
          <NavbarContainer>
            <Breakpoint small down>
              <NavLogo to="/">
                <img
                  style={{ maxWidth: '25px', marginRight: '5px' }}
                  src={logo}
                  alt="climetech"
                />
                <Text
                  style={{
                    fontSize: '25px',
                    fontFamily: 'Poppins',
                    lineHeight: '0px',
                  }}
                >
                  Clime
                  <Text
                    color="#18A841"
                    style={{ marginLeft: '5px' }}
                    inherit
                    component="span"
                  >
                    Tech
                  </Text>
                </Text>
              </NavLogo>
            </Breakpoint>
            <Breakpoint medium up>
              <NavLogo to="/">
                <img
                  style={{ maxWidth: '30px', marginRight: '10px' }}
                  src={logo}
                  alt="climetech"
                />
                <Text
                  style={{
                    fontSize: '30px',
                    lineHeight: '30px',
                    fontFamily: 'Poppins',
                  }}
                >
                  Clime
                  <Text
                    color="#18A841"
                    style={{ marginLeft: '5px' }}
                    inherit
                    component="span"
                  >
                    Tech
                  </Text>
                </Text>
              </NavLogo>
            </Breakpoint>

            <MenuIcon onClick={handleMyClick}>
              {openNavigation ? (
                <BiX color="#202020" />
              ) : (
                <BiMenu color="#202020" />
              )}
            </MenuIcon>

            <Menu onClick={handleClick} click={openNavigation}>
              <DropDownLi>
                <Dropbtn>Company</Dropbtn>
                <DropDownContent>
                  <MenuLink onClick={closeMenu} to="/about">
                    About Us{' '}
                  </MenuLink>
                  <MenuLink onClick={closeMenu} to="/contact">
                    Support{' '}
                  </MenuLink>
                  <MenuLink onClick={closeMenu} to="/request-demo">
                    Request Demo{' '}
                  </MenuLink>
                  <MenuLink onClick={closeMenu} to="/view-demo">
                    View Demo{' '}
                  </MenuLink>
                </DropDownContent>
              </DropDownLi>

              <MenuLink
                style={{ fontWeight: '500' }}
                onClick={closeMenu}
                to="/services"
              >
                Solutions
              </MenuLink>

              {/* <DropDownLi>
                <Dropbtn>Use Cases</Dropbtn>
                <DropDownContent>
                  <MenuLink
                    onClick={closeMenu}
                    to="/remote-work-emissions-for-business"
                  >
                    For Businesses
                  </MenuLink>
                  <MenuLink
                    onClick={closeMenu}
                    to="/energy-efficiency-programs"
                  >
                    For Cities
                  </MenuLink>
                </DropDownContent>
              </DropDownLi> */}

              <DropDownLi>
                <Dropbtn>Learn</Dropbtn>
                <DropDownContent>
                  <MenuLink onClick={closeMenu} to="/why-climetech">
                    Why Clime Tech{' '}
                  </MenuLink>
                  {/* <MenuLink onClick={closeMenu} to="/learn">
                    Academy{' '}
                  </MenuLink> */}
                  <MenuLink onClick={closeMenu} to="/security">
                    Security{' '}
                  </MenuLink>
                  {/* <MenuLink onClick={closeMenu} to="/faq">
                    FAQs{' '}
                  </MenuLink> */}
                  <MenuLink onClick={closeMenu} to="/referral-scheme">
                    Individuals Referral Program{' '}
                  </MenuLink>
                </DropDownContent>
              </DropDownLi>

              <MenuLink
                style={{ fontWeight: '500' }}
                onClick={closeMenu}
                to="/pricing"
              >
                Pricing
              </MenuLink>

              <DropDownLi>
                <Dropbtn>Resources</Dropbtn>
                <DropDownContent>
                  <MenuLink onClick={closeMenu} to="/resources">
                    Whitepaper
                  </MenuLink>
                  <MenuLink onClick={closeMenu} to="/blogs">
                    Blog{' '}
                  </MenuLink>
                </DropDownContent>
              </DropDownLi>
            </Menu>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
