/* eslint-disable react/no-unescaped-entities */
import {
  Container,
  Grid,
  Group,
  Image,
  Modal,
  Paper,
  Select,
  SimpleGrid,
  Space,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import React from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { Breakpoint } from 'react-socks';
import { supportApi } from '../../api';
import { Button } from '../../styles/Globalstyles.styled';
import CustomisedQuote from './CustomisedQuote';
import { FaArrowRight } from 'react-icons/fa';

function Iframe(props) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : '' }}
    />
  );
}
const BusinessDemo = () => {
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [business, setBusiness] = React.useState('');
  const [businessSize, setBusinessSize] = React.useState('');
  const [demoAccess, setDemoAccess] = React.useState(false);
  const [customisedQuote, setCustomisedQuote] = React.useState(false);

  const checkEmail = (email) => {
    const pattern =
      /^[^@]+@(?!(gmail|hotmail)\.com$)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name && email && phone && business && businessSize) {
      if (checkEmail(email)) {
        supportApi
          .sendDemoQuery({
            name,
            email,
            phone,
            business,
            businessSize,
          })
          .then((res) => {
            setDemoAccess(true);
            toast.success('Submitted Successfully');
          });
      } else {
        toast.error('Please fill the work email');
      }
    } else {
      toast.error('Please fill all the fields');
    }
  };

  return (
    <>
      {!demoAccess && (
        <>
          <Breakpoint small down>
            <Container style={{ padding: '50px 20px' }}>
              <Grid justify={'space-around'} columns={12} gutter={50}>
                <Grid.Col lg={5} md={12} style={{ margin: 'auto' }}>
                  <Group direction="column" position="center" spacing={20}>
                    <Text
                      weight={600}
                      style={{
                        fontFamily: 'Poppins',
                        fontSize: '32px',
                        lineHeight: '1.05',
                      }}
                    >
                      YOU ARE{' '}
                    </Text>
                    <Image
                      src="/assets/sme-businesses.png"
                      alt="SME BUSINESSES"
                    />
                    <Text
                      weight={500}
                      style={{
                        fontFamily: 'Poppins',
                        fontSize: '28px',
                      }}
                    >
                      A Business
                    </Text>
                    <Text
                      color={'dimmed'}
                      variant="link"
                      component={Link}
                      to="/view-demo"
                    >
                      Not a business? Go back to choose the right option
                    </Text>
                  </Group>
                </Grid.Col>
                <Grid.Col lg={5} md={12} style={{ margin: 'auto' }}>
                  <Group
                    direction="column"
                    spacing={20}
                    position="center"
                    style={{
                      background: 'white',
                      padding: '40px',
                      borderRadius: '20px',
                      border: '1px solid #202020',
                    }}
                    grow
                  >
                    <Title
                      order={2}
                      align="center"
                      style={{
                        fontSize: '24px',
                        lineHeight: '1.05',
                        fontWeight: '500',
                      }}
                    >
                      Fill your details to view demo
                    </Title>
                    <TextInput
                      styles={{
                        input: {
                          borderColor: '#495057',
                        },
                      }}
                      size="md"
                      radius="xl"
                      placeholder="Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />

                    <TextInput
                      styles={{
                        input: {
                          borderColor: '#495057',
                        },
                      }}
                      size="md"
                      radius="xl"
                      placeholder="Email id"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <TextInput
                      styles={{
                        input: {
                          borderColor: '#495057',
                        },
                      }}
                      size="md"
                      radius="xl"
                      placeholder="Phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    />
                    <TextInput
                      styles={{
                        input: {
                          borderColor: '#495057',
                        },
                      }}
                      size="md"
                      radius="xl"
                      placeholder="Name of your Business"
                      value={business}
                      onChange={(e) => setBusiness(e.target.value)}
                      required
                    />

                    <Select
                      styles={{
                        input: {
                          borderColor: '#495057',
                        },
                      }}
                      size="md"
                      radius="xl"
                      placeholder="Number of Employees"
                      value={businessSize}
                      onChange={setBusinessSize}
                      data={[
                        { value: '1-10', label: '1-10' },
                        { value: '11-100', label: '11-100' },
                        { value: '101-2000', label: '101-2000' },
                        { value: '2000+', label: '2000+' },
                      ]}
                      required
                    />

                    <Button
                      style={{
                        fontWeight: 500,
                        fontSize: '16px',
                        width: '150px',
                      }}
                      radius="xl"
                      onClick={handleSubmit}
                    >
                      Watch Demo{' '}
                    </Button>
                  </Group>
                </Grid.Col>
              </Grid>
            </Container>
          </Breakpoint>
          <Breakpoint medium up>
            <Container
              fluid
              style={{
                padding: '50px 100px',
                maxWidth: '1512px',
                margin: '0 auto',
              }}
            >
              <Grid justify={'space-around'} columns={12}>
                <Grid.Col lg={5} md={12} style={{ margin: 'auto' }}>
                  <Group direction="column" position="center" spacing={20}>
                    <Text
                      weight={600}
                      style={{
                        fontFamily: 'Poppins',
                        fontSize: '32px',
                        lineHeight: '1.05',
                      }}
                    >
                      YOU ARE{' '}
                    </Text>
                    <Image
                      src="/assets/sme-businesses.png"
                      alt="SME BUSINESSES"
                    />
                    <Text
                      weight={500}
                      style={{
                        fontFamily: 'Poppins',
                        fontSize: '28px',
                      }}
                    >
                      A Business
                    </Text>
                    <Text
                      color={'dimmed'}
                      variant="link"
                      component={Link}
                      to="/view-demo"
                    >
                      Not a business? Go back to choose the right option
                    </Text>
                  </Group>
                </Grid.Col>
                <Grid.Col lg={5} md={12}>
                  <Group
                    direction="column"
                    spacing={20}
                    position="center"
                    style={{
                      background: 'white',
                      padding: '40px',
                      borderRadius: '20px',
                      border: '1px solid #202020',
                    }}
                    grow
                  >
                    <Title
                      order={2}
                      align="center"
                      style={{
                        fontSize: '24px',
                        lineHeight: '1.05',
                        fontWeight: '500',
                      }}
                    >
                      Fill your details to view demo
                    </Title>
                    <TextInput
                      styles={{
                        input: {
                          borderColor: '#495057',
                        },
                      }}
                      size="md"
                      radius="xl"
                      placeholder="Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />

                    <TextInput
                      styles={{
                        input: {
                          borderColor: '#495057',
                        },
                      }}
                      size="md"
                      radius="xl"
                      placeholder="Email id"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <TextInput
                      styles={{
                        input: {
                          borderColor: '#495057',
                        },
                      }}
                      size="md"
                      radius="xl"
                      placeholder="Phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    />
                    <TextInput
                      styles={{
                        input: {
                          borderColor: '#495057',
                        },
                      }}
                      size="md"
                      radius="xl"
                      placeholder="Name of your Business"
                      value={business}
                      onChange={(e) => setBusiness(e.target.value)}
                      required
                    />

                    <Select
                      styles={{
                        input: {
                          borderColor: '#495057',
                        },
                      }}
                      size="md"
                      radius="xl"
                      placeholder="Number of Employees"
                      value={businessSize}
                      onChange={setBusinessSize}
                      data={[
                        { value: '1-10', label: '1-10' },
                        { value: '11-100', label: '11-100' },
                        { value: '101-2000', label: '101-2000' },
                        { value: '2000+', label: '2000+' },
                      ]}
                      required
                    />

                    <Button
                      style={{
                        fontWeight: 500,
                        fontSize: '16px',
                        width: '150px',
                      }}
                      radius="xl"
                      onClick={handleSubmit}
                    >
                      Watch Demo{' '}
                    </Button>
                  </Group>
                </Grid.Col>
              </Grid>
            </Container>
          </Breakpoint>
        </>
      )}
      {demoAccess && (
        <>
          <Breakpoint small down>
            <Container fluid>
              <Modal
                centered
                overlayColor="#7f7f7f"
                overlayOpacity={0.25}
                radius={'md'}
                zIndex={5}
                opened={customisedQuote}
                onClose={() => setCustomisedQuote(false)}
                title="Let's Get a Customised Quote"
                size={600}
                styles={{
                  title: {
                    fontWeight: '600',
                    fontSize: '20px',
                    margin: '0 auto',
                  },
                  modal: {
                    borderRadius: '20px',
                    border: '1px solid #202020',
                  },
                }}
              >
                <CustomisedQuote setCustomisedQuote={setCustomisedQuote} />
              </Modal>
              <Group direction="column" position="center">
                <Text
                  align="center"
                  weight={500}
                  style={{
                    fontFamily: 'Poppins',
                    fontSize: '28px',
                  }}
                >
                  Welcome To Clime Tech Inc{' '}
                </Text>
                <Text
                  align="center"
                  weight={500}
                  style={{
                    fontFamily: 'Poppins',
                    fontSize: '32px',
                  }}
                >
                  See What We Can Do{' '}
                </Text>
                <Space h="lg" />
                <Group direction="column">
                  <Button
                    onClick={() => {
                      setCustomisedQuote(true);
                    }}
                  >
                    <Text align="center">
                      Get Customized Quote <FaArrowRight />
                    </Text>
                  </Button>
                </Group>
                <Space h="lg" />
                <Paper
                  shadow="xl"
                  withBorder
                  style={{
                    background: '#FFFFFF',
                    boxShadow: '4px 4px 7px 1px rgba(0, 0, 0, 0.15)',
                    borderRadius: '10px',
                    padding: '20px 40px',
                    maxWidth: '382px',
                  }}
                >
                  <SimpleGrid cols={1}>
                    <Iframe
                      iframe={
                        '<iframe src="https://www.youtube.com/embed/6n3x94qDcsY" title="Overview" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
                      }
                      allow="autoplay"
                    />
                    <Text
                      size="xl"
                      align="center"
                      weight={500}
                      style={{ margin: 'auto' }}
                    >
                      Overview
                    </Text>
                  </SimpleGrid>
                </Paper>
                <Paper
                  shadow="xl"
                  withBorder
                  style={{
                    background: '#FFFFFF',
                    boxShadow: '4px 4px 7px 1px rgba(0, 0, 0, 0.15)',
                    borderRadius: '10px',
                    padding: '20px 40px',
                    maxWidth: '382px',
                  }}
                >
                  <SimpleGrid cols={1}>
                    <Iframe
                      iframe={
                        '<iframe  src="https://www.youtube.com/embed/PPTbAR6OX9Q" title="Emissions Measurement and Action" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
                      }
                      allow="autoplay"
                    />
                    <Text
                      size="xl"
                      align="center"
                      weight={500}
                      style={{
                        margin: 'auto',
                      }}
                    >
                      Emissions Measurement and Action{' '}
                    </Text>
                  </SimpleGrid>
                </Paper>
                <Paper
                  shadow="xl"
                  withBorder
                  style={{
                    background: '#FFFFFF',
                    boxShadow: '4px 4px 7px 1px rgba(0, 0, 0, 0.15)',
                    borderRadius: '10px',
                    padding: '20px 40px',
                    maxWidth: '382px',
                  }}
                >
                  <SimpleGrid cols={1}>
                    <Iframe
                      iframe={
                        '<iframe  src="https://www.youtube.com/embed/Ch0zBBmExUs" title="Climate Action" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
                      }
                      allow="autoplay"
                    />
                    <Text
                      size="xl"
                      align="center"
                      weight={500}
                      style={{
                        margin: 'auto',
                      }}
                    >
                      Climate Action{' '}
                    </Text>
                  </SimpleGrid>
                </Paper>
                <Paper
                  shadow="xl"
                  withBorder
                  style={{
                    background: '#FFFFFF',
                    boxShadow: '4px 4px 7px 1px rgba(0, 0, 0, 0.15)',
                    borderRadius: '10px',
                    padding: '20px 40px',
                    maxWidth: '382px',
                  }}
                >
                  <SimpleGrid cols={1}>
                    <Iframe
                      iframe={
                        '<iframe  src="https://www.youtube.com/embed/dOV_UvDJM34" title="Community Support" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
                      }
                      allow="autoplay"
                    />
                    <Text
                      size="xl"
                      align="center"
                      weight={500}
                      style={{
                        margin: 'auto',
                      }}
                    >
                      Community Support{' '}
                    </Text>
                  </SimpleGrid>
                </Paper>
              </Group>
              <Space h="lg" />
            </Container>
          </Breakpoint>
          <Breakpoint medium up>
            <Container
              fluid
              style={{
                padding: '50px 100px',
                maxWidth: '1512px',
                margin: '0 auto',
              }}
            >
              <Modal
                centered
                overlayColor="#7f7f7f"
                overlayOpacity={0.25}
                radius={'md'}
                zIndex={5}
                opened={customisedQuote}
                onClose={() => setCustomisedQuote(false)}
                title="Let's Get a Customised Quote"
                size={600}
                styles={{
                  title: {
                    fontWeight: '600',
                    fontSize: '20px',
                    margin: '0 auto',
                  },
                  modal: {
                    borderRadius: '20px',
                    border: '1px solid #202020',
                  },
                }}
              >
                <CustomisedQuote setCustomisedQuote={setCustomisedQuote} />
              </Modal>
              <Group direction="column" position="center">
                <Text
                  align="center"
                  weight={500}
                  style={{
                    fontFamily: 'Poppins',
                    fontSize: '28px',
                  }}
                >
                  Welcome To Clime Tech Inc{' '}
                </Text>
                <Text
                  align="center"
                  weight={500}
                  style={{
                    fontFamily: 'Poppins',
                    fontSize: '32px',
                  }}
                >
                  See What We Can Do{' '}
                </Text>
                <Space h="lg" />
                <Group direction="column">
                  <Button
                    onClick={() => {
                      setCustomisedQuote(true);
                    }}
                  >
                    <Text align="center">
                      Get Customized Quote <FaArrowRight />
                    </Text>
                  </Button>
                </Group>
                <Space h="lg" />
                <Paper
                  shadow="xl"
                  withBorder
                  style={{
                    background: '#FFFFFF',
                    boxShadow: '4px 4px 7px 1px rgba(0, 0, 0, 0.15)',
                    borderRadius: '10px',
                    padding: '20px 60px',
                  }}
                >
                  <SimpleGrid cols={2}>
                    <Iframe
                      iframe={
                        '<iframe width="560" height="315" src="https://www.youtube.com/embed/6n3x94qDcsY" title="Overview" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
                      }
                      allow="autoplay"
                    />
                    <Text
                      size={'xl'}
                      weight={500}
                      style={{ margin: 'auto', fontSize: '28px' }}
                    >
                      Overview
                    </Text>
                  </SimpleGrid>
                </Paper>

                <Paper
                  shadow="xl"
                  withBorder
                  style={{
                    background: '#FFFFFF',
                    boxShadow: '4px 4px 7px 1px rgba(0, 0, 0, 0.15)',
                    borderRadius: '10px',
                    padding: '20px 60px',
                  }}
                >
                  <SimpleGrid cols={2}>
                    <Text
                      size="xl"
                      weight={500}
                      style={{
                        margin: 'auto',
                        fontSize: '28px',
                      }}
                    >
                      Emissions Measurement and Action{' '}
                    </Text>
                    <Iframe
                      iframe={
                        '<iframe width="560" height="315" src="https://www.youtube.com/embed/PPTbAR6OX9Q" title="Emissions Measurement and Action" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
                      }
                      allow="autoplay"
                    />
                  </SimpleGrid>
                </Paper>
                <Paper
                  shadow="xl"
                  withBorder
                  style={{
                    background: '#FFFFFF',
                    boxShadow: '4px 4px 7px 1px rgba(0, 0, 0, 0.15)',
                    borderRadius: '10px',
                    padding: '20px 60px',
                  }}
                >
                  <SimpleGrid cols={2}>
                    <Iframe
                      iframe={
                        '<iframe width="560" height="315" src="https://www.youtube.com/embed/Ch0zBBmExUs" title="Climate Action" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
                      }
                      allow="autoplay"
                    />
                    <Text
                      size="xl"
                      weight={500}
                      style={{
                        margin: 'auto',
                        fontSize: '28px',
                      }}
                    >
                      Climate Action{' '}
                    </Text>
                  </SimpleGrid>
                </Paper>
                <Paper
                  shadow="xl"
                  withBorder
                  style={{
                    background: '#FFFFFF',
                    boxShadow: '4px 4px 7px 1px rgba(0, 0, 0, 0.15)',
                    borderRadius: '10px',
                    padding: '20px 60px',
                  }}
                >
                  <SimpleGrid cols={2}>
                    <Text
                      size="xl"
                      weight={500}
                      style={{
                        margin: 'auto',
                        fontSize: '28px',
                      }}
                    >
                      Community Support{' '}
                    </Text>
                    <Iframe
                      iframe={
                        '<iframe width="560" height="315" src="https://www.youtube.com/embed/dOV_UvDJM34" title="Community Support" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
                      }
                      allow="autoplay"
                    />
                  </SimpleGrid>
                </Paper>
              </Group>
            </Container>
          </Breakpoint>
        </>
      )}
    </>
  );
};

export default BusinessDemo;
