import React, { useEffect } from 'react';
import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';
import { supportApi } from '../../api';

const theme = {
  background: '#f5f8ff',
  fontFamily: 'Poppins',
  headerBgColor: '#e8f9eb',
  headerFontColor: '#0E5E46',
  headerFontSize: '15px',
  botBubbleColor: '#0E5E46',
  botFontColor: '#f5f8fb',
  userBubbleColor: '#0E5E46',
  userFontColor: '#f5f8fb',
};

export const Review = ({ steps }) => {
  const { name, email, query } = steps;

  useEffect(() => {
    console.log(name, email, query);
    supportApi
      .sendSupportQuery({
        name: 'ClimeTech Assistant',
        email: email.value,
        query: query.value,
      })
      .then((res) => {
        console.log(res);
      });
  }, [name, email, query]);

  return <>Thank You ! We will reach Back to you shortly</>;
};

const Bot = () => {
  var myDate = new Date();
  var hrs = myDate.getHours();

  let greet;

  if (hrs < 12) greet = 'Good Morning';
  else if (hrs >= 12 && hrs <= 17) greet = 'Good Afternoon';
  else if (hrs >= 17 && hrs <= 24) greet = 'Good Evening';

  const [opened, setOpened] = React.useState(false);

  useEffect(() => {
    setTimeout(() => {
      setOpened(false);
    }, 5000);
  }, []);

  const toggleFloating = ({ opened }) => {
    setOpened(opened);
  };

  const steps = [
    {
      id: '0',
      message: greet,
      trigger: '1',
    },
    {
      id: '1',
      message:
        'Hi there, I am the live CEO of Clime Tech not a bot. What can I help you with? (You’ll be asked to give us your email below... ok that part is a bot! you’ll get our response via email.)',
      trigger: '3',
    },
    {
      id: '3',
      message: 'Tell me your Email?',
      trigger: 'email',
    },
    {
      id: 'email',
      user: true,
      trigger: 'reachout',
    },
    {
      id: 'reachout',
      message: 'Tell me your query we will get back to you shortly.',
      trigger: 'query',
    },
    {
      id: 'query',
      user: true,
      trigger: 'end',
    },
    {
      id: 'end',
      component: <Review />,
      asMessage: true,
      end: true,
    },
  ];

  const config = {
    botAvatar: '/assets/founder.svg',
    floating: true,
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <ChatBot
          headerTitle="ClimeTech Assistant"
          steps={steps}
          {...config}
          opened={opened}
          toggleFloating={toggleFloating}
        />
      </ThemeProvider>
    </>
  );
};

export default Bot;
