import React from 'react';
import {
  createStyles,
  Text,
  Container,
  ActionIcon,
  Group,
  Space,
} from '@mantine/core';
import { ReactComponent as Logo } from '../../darklogo.svg';
import { BsFacebook, BsLinkedin, BsTwitter } from 'react-icons/bs';
import { FaDiscord } from 'react-icons/fa';

const useStyles = createStyles((theme) => ({
  footer: {
    paddingTop: theme.spacing.xl * 2,
    paddingBottom: theme.spacing.xl * 2,
    backgroundColor: '#FFFFFF',
    color: '#202020',
    fontWeight: 500,
    borderTop: `1px solid #202020`,
  },

  logo: {
    [theme.fn.smallerThan('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  description: {
    marginTop: 5,

    [theme.fn.smallerThan('sm')]: {
      marginTop: theme.spacing.xs,
      textAlign: 'center',
    },
  },

  inner: {
    display: 'flex',

    justifyContent: 'space-between',

    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  groups: {
    display: 'flex',
    flexWrap: 'wrap',

    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  wrapper: {
    width: 160,
    paddingLeft: 50,
  },

  link: {
    display: 'block',
    color: '#202020',
    fontWeight: '300',
    fontSize: theme.fontSizes.sm,
    paddingTop: 3,
    paddingBottom: 3,

    '&:hover': {
      textDecoration: 'underline',
    },
  },

  title: {
    fontSize: theme.fontSizes.lg,
    fontWeight: 700,
    marginBottom: theme.spacing.xs / 2,
    color: '#202020',
  },

  afterFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing.xl,
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
    borderTop: `1px solid #202020`,

    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',
      paddingBottom: 0,
    },
  },

  social: {
    [theme.fn.smallerThan('sm')]: {
      marginTop: theme.spacing.xs,
    },
  },
}));

export default function Footer() {
  const data = [
    // {
    //   title: 'Learn about Carbon footprint',
    //   links: [
    //     { label: 'Carbon footprint 000', link: '/learn/carbonfootprint000' },
    //     { label: 'Carbon footprint 101', link: '/learn/carbonfootprint101' },
    //   ],
    // },

    {
      title: 'Contact us',
      links: [
        { label: 'About', link: '/about' },
        { label: 'Contact', link: '/contact' },
        { label: 'Privacy Policy', link: '/privacy-policy' },
        { label: 'Terms of Engagement', link: '/terms-and-condition' },
      ],
    },
  ];
  const { classes } = useStyles();
  const groups = data.map((group) => {
    const links = group.links.map((link, index) => (
      <Text
        key={index}
        className={classes.link}
        component="a"
        href={link.link}
        target={'_blank'}
      >
        {link.label}
      </Text>
    ));

    return (
      <div className={classes.wrapper} key={group.title}>
        <Text className={classes.title}>{group.title}</Text>
        {links}
      </div>
    );
  });
  return (
    <footer className={classes.footer}>
      <Container className={classes.inner}>
        <div className={classes.logo}>
          <Group direction="row">
            <Logo width="25px" height="25px" />
            <Text size="xl">
              Clime
              <Text color="#18A841" inherit component="span">
                {' '}
                Tech
              </Text>
            </Text>
          </Group>
          <Space h="20px" />
          <Text size="md" align="center" className={classes.description}>
            Together, we create a world we want to live in.
          </Text>
        </div>
        <div className={classes.groups}>{groups}</div>
      </Container>
      <Container className={classes.afterFooter}>
        <Text size="sm">&copy; 2022 Clime Tech, All Rights Reserved.</Text>
        <Group spacing={0} className={classes.social} position="right" noWrap>
          <ActionIcon
            size="lg"
            style={{ color: '#202020' }}
            component="a"
            href="https://discord.com/invite/3jgBpTdaMn"
            target={'_blank'}
          >
            <FaDiscord size={18} />
          </ActionIcon>
          <ActionIcon
            size="lg"
            style={{ color: '#202020' }}
            component="a"
            href="https://twitter.com/ClimeTechInc"
            target={'_blank'}
          >
            <BsTwitter size={18} />
          </ActionIcon>
          <ActionIcon
            size="lg"
            style={{ color: '#202020' }}
            component="a"
            href="https://www.linkedin.com/company/clime-tech/"
            target={'_blank'}
          >
            <BsLinkedin size={18} />
          </ActionIcon>
          <ActionIcon
            size="lg"
            style={{ color: '#202020' }}
            component="a"
            href="https://www.facebook.com/ClimeTech"
            target={'_blank'}
          >
            <BsFacebook size={18} />
          </ActionIcon>
        </Group>
      </Container>
    </footer>
  );
}
