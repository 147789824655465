import React, { lazy, Suspense, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

import { Button, Group, MantineProvider, Text } from '@mantine/core';
import { Toaster } from 'react-hot-toast';
import { BreakpointProvider } from 'react-socks';

import * as Sentry from '@sentry/react';
import Bot from './components/Bot';
import Loader from './components/Loader';
import HomeAppShell from './layouts/HomeAppShell';
import importRetry from './importRetry';
import CityDemo from './pages/ViewDemo/CityDemo';
import BusinessDemo from './pages/ViewDemo/BusinessDemo';

const Home = lazy(() => importRetry(() => import('./pages/Home')));
const About = lazy(() => importRetry(() => import('./pages/About')));

const RequestDemo = lazy(() =>
  importRetry(() => import('./pages/RequestDemo')),
);
const ViewDemo = lazy(() => importRetry(() => import('./pages/ViewDemo')));
const Pricing = lazy(() => importRetry(() => import('./pages/Pricing')));

const Services = lazy(() => importRetry(() => import('./pages/Services')));
const Security = lazy(() => importRetry(() => import('./pages/Security')));
const UseCase1 = lazy(() =>
  importRetry(() => import('./pages/UseCase/UseCase1')),
);
const UseCase2 = lazy(() =>
  importRetry(() => import('./pages/UseCase/UseCase2')),
);
const WhyClimeTech = lazy(() =>
  importRetry(() => import('./pages/WhyClimeTech')),
);
const ReferralProgram = lazy(() =>
  importRetry(() => import('./pages/ReferralProgram')),
);

const Docs = lazy(() => importRetry(() => import('./pages/Documents')));
const ContactUs = lazy(() => importRetry(() => import('./pages/ContactUs')));
const Privacy = lazy(() => importRetry(() => import('./pages/Privacy')));
const Terms = lazy(() => importRetry(() => import('./pages/Terms')));

const Blogs = lazy(() => importRetry(() => import('./pages/Blogs')));
const Blog1 = lazy(() => importRetry(() => import('./pages/Blogs/Blog1')));
const Blog2 = lazy(() => importRetry(() => import('./pages/Blogs/Blog2')));
const Blog3 = lazy(() => importRetry(() => import('./pages/Blogs/Blog3')));

const Learn = lazy(() => importRetry(() => import('./pages/Learn')));
const Carbonfootprint000 = lazy(() =>
  importRetry(() => import('./pages/Learn/topics/carbonfootprint000')),
);
const Carbonfootprint101 = lazy(() =>
  importRetry(() => import('./pages/Learn/topics/carbonfootprint101')),
);
const Nft = lazy(() => importRetry(() => import('./pages/Learn/topics/nfts')));
const Dao = lazy(() => importRetry(() => import('./pages/Learn/topics/dao')));
const Web3 = lazy(() => importRetry(() => import('./pages/Learn/topics/web3')));
const Cryptocurrency = lazy(() =>
  importRetry(() => import('./pages/Learn/topics/cryptocurrency')),
);

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export const FallbackPage = () => {
  return (
    <>
      <Group direction="column" align="center" style={{ paddingTop: '250px' }}>
        <Text fontSize="18px">Looks Like Something went Wrong</Text>
        <Button component="a" href="/">
          Reload page
        </Button>
      </Group>
    </>
  );
};

function App() {
  return (
    <Sentry.ErrorBoundary fallback={<FallbackPage />}>
      <MantineProvider theme={{ fontFamily: 'Montserrat' }}>
        <BreakpointProvider>
          <Toaster />
          <Router>
            <ScrollToTop />
            <HomeAppShell>
              <Suspense fallback={<Loader />}>
                <Routes>
                  <Route element={<About />} path="/about" />
                  <Route element={<Blogs />} path="/blogs" />
                  <Route
                    exact
                    element={<Blog1 />}
                    path="/blogs/benefits-of-using-energy-efficient-devices"
                  />
                  <Route
                    exact
                    element={<Blog2 />}
                    path="/blogs/demographic-climate-change"
                  />
                  <Route
                    exact
                    element={<Blog3 />}
                    path="/blogs/15-easy-climate-actions-you-can-take-today"
                  />
                  <Route element={<ContactUs />} path="/contact" />
                  <Route element={<RequestDemo />} path="/request-demo" />
                  <Route element={<ViewDemo />} path="/view-demo" />
                  <Route element={<CityDemo />} path="/view-demo/city" />
                  <Route
                    element={<BusinessDemo />}
                    path="/view-demo/business"
                  />
                  <Route element={<Docs />} path="/resources" />
                  <Route
                    element={<UseCase1 />}
                    path="/remote-work-emissions-for-business"
                  />
                  <Route
                    element={<UseCase2 />}
                    path="/energy-efficiency-programs"
                  />
                  <Route element={<Services />} path="/services" />
                  <Route element={<WhyClimeTech />} path="/why-climetech" />
                  <Route element={<Security />} path="/security" />
                  <Route
                    element={<ReferralProgram />}
                    path="/referral-scheme"
                  />
                  <Route exact element={<Pricing />} path="/pricing" />
                  <Route exact element={<Learn />} path="/learn" />

                  <Route
                    exact
                    element={<Carbonfootprint101 />}
                    path="/learn/carbonfootprint101"
                  />
                  <Route
                    exact
                    element={<Carbonfootprint000 />}
                    path="/learn/carbonfootprint000"
                  />
                  <Route exact element={<Web3 />} path="/learn/web3" />
                  <Route exact element={<Dao />} path="/learn/dao" />
                  <Route exact element={<Nft />} path="/learn/nfts" />
                  <Route
                    exact
                    element={<Cryptocurrency />}
                    path="/learn/cryptocurrency"
                  />

                  <Route exact element={<Privacy />} path="/privacy-policy" />
                  <Route
                    exact
                    element={<Terms />}
                    path="/terms-and-condition"
                  />

                  <Route element={<Home />} path="/" />
                </Routes>
              </Suspense>
            </HomeAppShell>
          </Router>
          <Bot />
        </BreakpointProvider>
      </MantineProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
